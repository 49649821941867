<script>
  import Base from '@backend/Base.vue';
  import BOGen from '@helper/BOGen';
  import Gen from '@helper/Gen';
  import draggable from 'vuedraggable'

  export default {
    name: "BoTopicCat",
    extends: Base,
    components: {
      draggable
    },
    data() {
      return {
        Name: "BoTopicCat",
        row1: {},
        hero: [],
        font_style: {},
        font_size: {},
        sub_font_size: {},
        titleLength:0,
        descLength:0,
        titlebnrLength:0,
        descbnrLength:0,
      }
    },
    mounted() {
      this.$set(this.$root, 'page', this)
      this.refreshData(() => {
        setTimeout(() => {
          this.$set(this.row, 'type', 'update')
        }, 500)
      })
    },
    methods: {
      endDrag() {
        BOGen.apirest('/' + this.Name, {
          data: this.data.data,
          type: 'sort'
        }, (err, resp) => {
          console.log(resp)
        }, "POST");
      },
      submitHero() {
        this.hero.type = 'submitHero'
        BOGen.apirest('/' + this.Name, this.hero, (err, resp) => {
          if (err) return Gen.info((err.resp.message || "please fill mandatory fields"), "danger", 3000,
            ".col-info")
          Gen.info((resp.message), "success", 3000, ".col-info")
          setTimeout(() => {
            $('#collapseExample').hide()
          }, 3000);
          console.log(resp)
        }, "POST");
      },
      showEdit() {
        if ($("#collapseExample").css("display") == "flex")
          $('#collapseExample').hide()
        else
          $('#collapseExample').css("display", "flex")
      },
      viewBanner(v) {
        $('#viewbanner').modal();
        $('#viewbanner').find('.img1').attr('src', v.image_bg)
        $('#viewbanner').find('.img2').attr('src', v.image_fg)
      }
    },
    watch: {
      '$route.query'(v) {
        this.refreshData()
      },
      'row.mht_title'(){
        this.titleLength = this.row.mht_title.length
      },
      'row.mht_detail'(){
        this.descLength = this.row.mht_detail.length
      },
      'row.mht_banner_desc'(){
        this.descbnrLength = this.row.mht_banner_desc.length
      },
      'row.mht_banner_title'(){
        this.titlebnrLength = this.row.mht_banner_title.length
      }
    }
  };
</script>

<template>
  <div class="container-fluid">
    <PageTitle></PageTitle>
    <div class="col-12" v-if="!$route.params.id">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title mb-0">Hero Image
          </h5>
        </div>

        <VForm @resp="submitHero" method="post">
          <div class="card-body">
          <div class="alert alert-info">
            Image Requirements for Article Category Hero Image :
            <ul class="mb-0 mt-2">
              <li><strong>Dimension: </strong><span>1350x420px</span></li>
              <li><strong>Format: </strong><span>jpeg,jpg,png</span></li>
              <li><strong>Max. Size: </strong><span>2 MB</span></li>
            </ul>
          </div>
            <div class="row">
              <div class="col-md-12"></div>
              <div class="col-md-8">
                <div class="wrap_slider_img">
                  <img :src="uploader(hero.ash_img)" class="img-responsive" />
                  <div class="slider_name">
                    <p>{{hero.ash_title}}</p>
                  </div>
                  <a href="javascript:;" @click="showEdit" class="bullet_edit"><i class="ti-marker-alt"></i></a>
                </div>
              </div>
            </div>
            <div class="row collapse mt-4" id="collapseExample">
              <div class="col-info col-12"></div>
              <div class="col-md-4 mb-3">
                <BoField name="ash_img">
                  <Uploader v-bind="validation('ash_img')" :attr="{required:1}" :param="{thumbnail:true}" name="ash_img"
                    v-model="hero.ash_img" type="hero_desktop_420" uploadType="cropping">
                  </Uploader>
                </BoField>
              </div>
              <div class="col-md-8 mb-3">
                <div class="row">
                  <div class="col-md-12">
                    <div class="panel_group">
                      <div class="panel_heading">Heading</div>
                      <div class="panel_body">
                        <div class="row">
                          <div class="col-md-9">
                            <BoField name="ash_title" v-model="hero.ash_title"></BoField>
                          </div>
                          <!-- <div class="col-md-2">
	                                <BoField :attr="{type:'color'}" name="ash_title_color"
	                                  v-model="hero.ash_title_color"></BoField>
	                              </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="panel_group">
                          <div class="panel_heading">Sub-Heading</div>
                          <div class="panel_body">
                            <div class="row">
                              <div class="col-md-12">
                                <BoField name="ash_sub_title" mandatory>
                                  <textarea name="ash_sub_title" rows="5" :attr="{minlength:'10'}"
                                    v-model="hero.ash_sub_title" v-bind="validation('ash_sub_title')"
                                    class="form-control"></textarea>
                                </BoField>

                                <!-- <BoField name="ash_sub_title" v-model="hero.ash_sub_title"></BoField> -->
                              </div>
                              <!-- <div class="col-md-2">
	                                    <div class="form-group">
	                                      <label class="control-label">Color </label>
	                                      <input class="form-control" type="color" id="favcolor" name="favcolor"
	                                        v-model="hero.ash_title_sub_color">
	                                    </div>
	                                  </div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 mt-3">
                    <button type="submit" class="btn btn-rounded btn-block btn-info btn-loading">Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </VForm>
      </div>
    </div>

    <div class="card">
      <div class="card-header" v-if="!$route.params.id">
        <VForm @resp="search">
          <div class="row">
            <div class="col-sm-5">
              <h5 class="card-title">{{ObjectName}} List</h5>
            </div>
            <div class="col-sm-3"></div>
            <div class="col-sm-3">
              <div class="form-group mb-0">
                <div class="input-group">
                  <input type="text" v-model="filter.search" v-on:keyup.enter="search" class="form-control"
                    placeholder="Search...">
                  <div class="input-group-append">
                    <button class="btn btn-info" type="button" @click="search()">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-1">
              <router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
            </div>
          </div>
        </VForm>
      </div>
      <div class="card-body">
        <div class="row" v-if="!$route.params.id">
          <div class="col-12">
            <div class="alert alert-info">
              Image Requirements for Banner :
              <ul class="mb-0 mt-2">
                <li><strong>Dimension: </strong><span>1350x550px (Banner Background, Hero Image), 545x360px (Banner Object)</span></li>
                <li><strong>Format: </strong><span>jpeg,jpg,png</span></li>
                <li><strong>Max. Size: </strong><span>2 MB</span></li>
              </ul>
            </div>
          </div>
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
            <div class="table-responsive ajax-table">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{{fields.mht_title}}</th>
                    <th>{{fields.mht_image}}</th>
                    <th>{{fields.mht_is_active}}</th>
                    <th width="120px">Action</th>
                  </tr>
                </thead>
                <draggable v-model="data.data" tag="tbody" @end="endDrag" style="cursor:pointer;">
                  <tr v-for="(v,k) in data.data" :key="k">
                    <td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
                    <td>{{v.mht_title}}</td>
                    <td>
                      <a href="javascript:;" class="btn btn-success m-l-15" @click="viewBanner(v)"><i
                          class="fa fa-image  m-r-15"></i>View Banner </a>
                    </td>
                    <td>
                      <StatusLabel :status="v.status"></StatusLabel>
                    </td>
                    <td class="btn-action">
                      <router-link v-if="page.moduleRole('Edit')" class="icon_action" :to="{name:Name,params:{id:v.id}}"
                        v-tooltip="'Edit'">
                        <i class="ti-marker-alt"></i>
                      </router-link>
                      <a v-if="page.moduleRole('ChangeStatus')" href="javascript:;" class="icon_action"
                        @click="changeStatus(k)" v-tooltip="'Change Status'">
                        <i class="icon-settings"></i>
                      </a>
                      <a v-if="page.moduleRole('Delete')" href="javascript:;" class="icon_action"
                        @click="deleteItem($event,k)" v-tooltip="'Remove'">
                        <i class="ti-trash"></i>
                      </a>
                    </td>
                  </tr>
                  <tr v-if="NotFound">
                    <td colspan="99">
                      <h3 class="tc">{{NotFound}}</h3>
                    </td>
                  </tr>
                  <tr v-if="data.data===false">
                    <td colspan="99">
                      <LoadingSpinner light></LoadingSpinner>
                    </td>
                  </tr>
                </draggable>
              </table>
            </div>
            <div class="card">
              <div class="panel-body">
                <div class="pull-right">
                  <Pagination :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card" v-if="$route.params.id">
          <VForm @resp="submitForm" method="post">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-md-8">
                  <h5 class="card-title">Have Sub Category?</h5>
                </div>
              </div>
              <div class="info"></div>
              <div class="row">
                <div class="col-4">
                    <div class="row">
                      <radio name="mht_have_subcategory" v-model="row.mht_have_subcategory" option="Y"
                        :attr="validation('mht_have_subcategory')">Yes</radio>
                      <radio name="mht_have_subcategory" v-model="row.mht_have_subcategory" option="N">No</radio>
                    </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-md-8">
                  <h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-5">
                  <BoField name="mht_title" :attr="{minlength:'3'}" v-model="row.mht_title" mandatory></BoField>
                  <small class="mb-2 d-inline-block text-info">{{validation('mht_title').maxlength-titleLength }} character remaining </small>
                  <BoField name="mht_detail" mandatory>
                    <textarea name="mht_detail" rows="5" :attr="{minlength:'10'}" v-model="row.mht_detail"
                      v-bind="validation('mht_detail')" class="form-control"></textarea>
                  </BoField>
                  <small class="mb-2 d-inline-block text-info">{{validation('mht_detail').maxlength-descLength}} character remaining </small>
                </div>
                <div class="col-sm-4">
                  <BoField name="mht_back_image">
                    <Uploader name="mht_back_image" type="back_hero_product" uploadType="cropping" mandatory
                      :param="{thumbnail:true}" v-model="row.mht_back_image"></Uploader>
                  </BoField>
                </div>
                <div class="col-sm-3">
                  <BoField name="mht_image">
                    <Uploader name="mht_image" type="hero_product" uploadType="cropping" :param="{thumbnail:true}"
                      v-model="row.mht_image"></Uploader>
                  </BoField>
                </div>
              </div>
            </div>
            
            <div class="card-body banner_content" v-if="row.mht_have_subcategory=='Y'">
              <hr>
              <div class="row mb-3">
                <div class="col-md-8">
                  <h5 class="card-title">Banner Content</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <BoField name="mht_banner_title" v-model="row.mht_banner_title"></BoField>
                  <small class="mb-2 d-inline-block text-info">{{validation('mht_banner_title').maxlength-titlebnrLength }} character remaining </small>
                  <BoField name="mht_banner_desc">
                    <textarea name="mht_banner_desc" class="form-control" rows="8" v-model="row.mht_banner_desc"
                      v-bind="validation('mht_banner_desc')"></textarea>
                  </BoField>
                  <small class="mb-2 d-inline-block text-info">{{validation('mht_banner_desc').maxlength-descbnrLength }} character remaining </small>
                </div>
                <div class="col-md-6">
                  <BoField name="mht_banner_image">
                    <Uploader name="mht_banner_image" type="hero_desktop" uploadType="cropping"
                      :param="{thumbnail:true}" v-model="row.mht_banner_image"></Uploader>
                  </BoField>
                </div>
              </div>
            </div>
            <div class="card-body seo_content">
              <hr>
              <div class="row mb-3">
                <div class="col-md-8">
                  <h5 class="card-title">Meta Content</h5>
                </div>
              </div>
              <div class="info"></div>
              <div class="row">
                <div class="col-sm-6">
                  <BoField name="mht_seo_title" :attr="{minlength:'3'}" v-model="row.mht_seo_title"></BoField>
                  <BoField name="mht_seo_keyword">
                    <TagsInput name="mht_seo_keyword" v-model="row.mht_seo_keyword"
                      :attr="validation('mht_seo_keyword')">
                    </TagsInput>
                  </BoField>
                </div>
                <div class="col-sm-6">
                  <BoField name="mht_seo_desc">
                    <textarea name="mht_seo_desc" rows="5" :attr="{minlength:'10'}" v-model="row.mht_seo_desc"
                      v-bind="validation('mht_seo_desc')" class="form-control"></textarea>
                  </BoField>
                </div>

                <div class="col-4">
                  <BoField name="mht_is_active">
                    <div class="row">
                      <radio name="mht_is_active" v-model="row.mht_is_active" option="Y"
                        :attr="validation('mht_is_active')">Active</radio>
                      <radio name="mht_is_active" v-model="row.mht_is_active" option="N">Inactive</radio>
                    </div>
                  </BoField>
                </div>
              </div>
            </div>
            <div class="col-xs-12">
              <div class="text-right">
                <button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-loading">Submit form <i
                    class="icon-arrow-right14 position-right"></i></button>
              </div>
            </div>

          </VForm>
        </div>

      </div>
    </div>

    <div class="modal" id="viewbanner">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Banner Product</h4>
            <button type="button" class="close" data-dismiss="modal">&times;</button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="wrap_banner">
                  <img class="img1" src="http://placehold.it/900x300" />

                  <div class="warp_img_prod">
                    <img class="img2" src="http://placehold.it/300x200" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>